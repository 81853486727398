import React from "react";
import { Marquee } from "@devnomic/marquee";
import KV from "../../assets/imgs/game_logo.png";
import Slogan from "../../assets/imgs/title_text.png";
import AD1 from "../../assets/imgs/ad_1.png";
import AD2 from "../../assets/imgs/ad_1.png";
import AD3 from "../../assets/imgs/ad_1.png";
import AD4 from "../../assets/imgs/ad_1.png";

import "@devnomic/marquee/dist/index.css";
import "./Home.css";

export default function Home() {
  return (
    <section id="home">
      <div className="kv">
        <div className="game_logo">
          <img alt="Knock Heros" src={KV} />
        </div>
        <div className="game_slogan">
          <img alt="Knock Heros - Adventure RPG" src={Slogan} />
        </div>
        <div className="game_description">
          <p>In this fantasy adventure RPG, collect heros and artifacts, explore a mysterious world with sword and magic!</p>
        </div>
        <Marquee className="marquee" fade={true}>
          <div>
            <img alt="hero 1" src={AD1} width={270} height={120} />
          </div>
          <div>
            <img alt="hero 2" src={AD2} width={270} height={120} />
          </div>
          <div>
            <img alt="hero 3" src={AD3} width={270} height={176} />
          </div>
          <div>
            <img alt="hero 4" src={AD4} width={270} height={176} />
          </div>
        </Marquee>
      </div>
    </section>
  );
}