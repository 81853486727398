import React from "react";

import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div>
        <span>2024 &copy; </span>Knock Studio
      </div>
      <div>
        <a href="/private-policy.html" target="_blank">Private Policy</a>
      </div>
    </footer>
  );
}