import React from "react";

import APP_ICON from "../../assets/imgs/game_icon.png";
import APPLE_STORE from "../../assets/imgs/apple_store.png";
import GOOGLE_PLAY_STORE from "../../assets/imgs/google_play_download.png";

import "./Download.css";

export default function Download() {
  return (
    <section id="download">
      <div className="title">
        <h1>Download</h1>
      </div>
      <div className="content" data-aos="flip-up">
        <img className="app_logo" alt="App" src={APP_ICON} width={90} />
        <div className="right_box">
          <h3>Enjoy the game!</h3>
          <div className="links">
            <img alt="Apple Store" src={APPLE_STORE} />
            <img alt="Google Play" src={GOOGLE_PLAY_STORE} />
          </div>
        </div>
      </div>
    </section>
  );
}