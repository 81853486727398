import React from "react";

import CORP_ICON from "../../assets/imgs/corp_logo_black.png";
import DISCORD_SOCIAL_ICON from "../../assets/imgs/discord_icon_1.png";
import FACEBOOK_SOCIAL_ICON from "../../assets/imgs/facebook_icon_1.png";

import "./ContactUs.css";

export default function ContactUs() {
  return (
    <section id="contact_us">
      <div className="title">
        <h1>Contact Us</h1>
      </div>
      <div className="follow_us">
        <div className="social_links">
          <a href="#">
            <img alt="Discord" src={DISCORD_SOCIAL_ICON} width={64} />
          </a>
          <a href="#">
            <img alt="Facebook" src={FACEBOOK_SOCIAL_ICON} width={64} />
          </a>
        </div>
        <p>Join the Discord community or access Facebook home page to get the latest news</p>
      </div>
      <div className="content" data-aos="flip-up">
        <img className="corp_logo" alt="App" src={CORP_ICON} width={128} height={128} />
        <div className="right_box">
          <h3>Knock Studio</h3>
          <p><span>Email:</span>konckHeroes@outlook.com</p>
          <p><span>Game Service:</span>KnockHeroes_Service@outlook.com</p>
          <p><span>Home Page:</span>https://home.kncokheroes.com</p>
        </div>
      </div>
    </section>
  );
}