import React from "react";

import HERO1 from "../../assets/imgs/hero_1.png";

import "./Hero.css";

export default function Heros() {
  return (
    <section id="heros">
      <div className="title">
        <h1>Heros</h1>
      </div>
      <div className="row">
        <div className="item" data-aos="fade-right">
          <img alt="Deanery's Dragon" src={HERO1} />
          <h3>Deanery's Dragon</h3>
          <p>Top Damage Dealer</p>
        </div>
        <div className="item" data-aos="fade-left">
          <img alt="Celestia Leafantler" src={HERO1} />
          <h3>Celestia Leafantler</h3>
          <p>Powerful Healer</p>
        </div>
      </div>
      <div className="row">
        <div className="item" data-aos="fade-right">
          <img alt="Knock Princess" src={HERO1} />
          <h3>Knock Princess</h3>
          <p>Seasonal Exclusive</p>
        </div>
        <div className="item" data-aos="fade-left">
          <img alt="Cedric Shiedheart" src={HERO1} />
          <h3>Cedric Shiedheart</h3>
          <p>Tank of Truth</p>
        </div>
      </div>
    </section>
  );
}